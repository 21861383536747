import Vue from 'vue';

const initialState = {
  // publisherData: [],
  id: null,
  matchedPercentage: 0,
  unmatchedPercentage: 0,
  matchedCodesCount: 0,
  unmatchedCodesCount: 0,
  // codes: [],
};

const state = Vue.observable(initialState);

export const publisherDataComputedState = {
  publisherData: {
    get() {
      return state;
    },
  },
};

export const publisherDataComputed = {
  // publisherData: {
  //   get() {
  //     return state.publisherData;
  //   },
  //   set(items) {
  //     state.publisherData = items;
  //   },
  // },
  // totalCodes() {
  //   return state.codes.length;
  // },
  // matchedCodesCount() {
  //   return state.matchedCodesCount;
  // },
  // unmatchedCodesCount() {
  //   return state.unmatchedCodesCount;
  // },
};

export const setLookupResult = (data) => {
  const {
    id,
    matchedCodesCount,
    unmatchedCodesCount,
    matchedPercentage,
    unmatchedPercentage,
  } = data;
  state.id = id;
  state.matchedCodesCount = matchedCodesCount;
  state.unmatchedCodesCount = unmatchedCodesCount;
  state.matchedPercentage = matchedPercentage;
  state.unmatchedPercentage = unmatchedPercentage;
};
