import { functions } from '@/config/firebase.config';

export const fetchIsrcData = (isrc_ids) => {
  return functions
    .httpsCallable('fetchIsrcDataSummary')({ isrc_ids })
    .then(({ data }) => data.data);
};

export const fetchBulkApplePlaylists = (urls) => {
  return functions
    .httpsCallable('fetchBulkApplePlaylists')({ urls })
    .then(({ data }) => data.data);
};

export const fetchBulkSpotifyPlaylists = (urls) => {
  return functions
    .httpsCallable('fetchBulkSpotifyPlaylists')({ urls })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};

export const fetchBulkPlaylists = ({
  apple_urls,
  spotify_urls,
  type = 'summary',
}) => {
  return functions
    .httpsCallable('fetchBulkPlaylistsSummary')({
      apple_urls,
      spotify_urls,
      type,
    })
    .then(({ data }) => data.data);
};

export const fetchPlaylists = ({ isrc_ids, productId }) => {
  return functions
    .httpsCallable('fetchPlaylists')({ isrc_ids, productId })
    .then(({ data }) => {
      if (data.error) throw new Error(data.message);
      return data.data;
    });
};
